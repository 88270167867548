import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { Location } from '@angular/common';
import { ViewerService } from '../state/viewer.service';
import { BehaviorSubject, Subject, timer } from 'rxjs';
import {
  Attachment,
  FieldDTO,
  FormSubmission,
  GuidedExperienceDTO,
  GuidedExperienceInstanceDTO,
  GxProcessing,
  SubmissionType,
  TaskDTO,
  ViewerFieldType,
  WindowMessageEventName,
} from '@next/shared/common';
import { FormGroup } from '@angular/forms';
import {
  NextAdminService,
  NextSubmissionService,
} from '@next/shared/next-services';
import { ErrorRibbonService } from '../state/error-ribbon.service';
import { ToastrService } from 'ngx-toastr';
import * as platform from 'platform';
import { TranslateService } from '@ngx-translate/core';
import { ActivatedRoute, Router } from '@angular/router';
import { HttpErrorResponse, HttpEventType } from '@angular/common/http';
import { filter, takeUntil, tap } from 'rxjs/operators';
import { animate, style, transition, trigger } from '@angular/animations';

@Component({
  selector: 'next-viewer',
  templateUrl: './viewer.component.html',
  styleUrls: ['./viewer.component.css'],
  animations: [
    trigger('fadeInOut',[
      transition(':enter',
        [ style({opacity: 0 }), animate('400ms ease-in-out', style({opacity: '*' }))]),
      transition(':leave',
        [ style({opacity: '*' }), animate('400ms ease-in-out', style({opacity: 0 }))])
    ])
  ],
  /* This will cause a viewer service to instantiate every time this Component is created.
   * We do this to reset the internal state fresh each time you navigate to the tool */
  providers: [ViewerService, ErrorRibbonService],
})

export class ViewerComponent implements OnInit, OnDestroy {
  SubmissionType: typeof SubmissionType = SubmissionType; // allow this enum to be used in the template
  obsCleanup: Subject<void> = new Subject<void>();

  @Input() instance$: BehaviorSubject<GuidedExperienceInstanceDTO> = new BehaviorSubject<GuidedExperienceInstanceDTO>(null);

  selectedPage: number;
  formData: unknown = { };

  loading = true;
  loadingMessage: string;
  loadingProgress = 0;
  time: number = 0;

  needsValidation: boolean;
  btnDisabled: boolean;
  scrolledToBottom = false;

  // Properties defined from instance
  experience: GuidedExperienceDTO;
  task: TaskDTO;
  prefill: unknown;
  submission: FormSubmission;
  attachments: Attachment[];

  constructor(
    public errorSvc: ErrorRibbonService,
    private viewerSvc: ViewerService,
    private submissionSvc: NextSubmissionService,
    private toastSvc: ToastrService,
    private translateSvc: TranslateService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private adminSvc: NextAdminService,
    private location: Location,
    private gxProcessing: GxProcessing,
  ) { }

  ngOnInit() {
    this.viewerSvc.form = new FormGroup({});
    this.instance$.pipe(
      filter(instance => !!instance),
      filter(instance => !!instance.experience),
      tap(instance => {
        this.experience = instance.experience;
        this.task = instance.task;
        this.prefill = instance.prefill;
        this.submission = instance.submission;
        this.attachments = instance.attachments ?? [];
      }),
      tap(instance => this.viewerSvc.initializeWEB(instance).then(result => {
        this.formData = result.initialData;
        this.loadingProgress = 100;
        setTimeout(() => {
          this.loading = false;
          }, 800);
      })),
      takeUntil(this.obsCleanup)
    ).subscribe();

    this.viewerSvc.getSelectedPage().pipe(
      tap(pageNum => this.selectedPage = pageNum),
      takeUntil(this.obsCleanup)
    ).subscribe();

    timer(0, 1000).pipe(
      takeUntil(this.obsCleanup)
    ).subscribe(() => {
        this.time++;
    });
  }

  ngOnDestroy(): void {
    this.obsCleanup.next();
    this.obsCleanup.complete();
  }

  async goNextPage(): Promise<void> {
    this.errorSvc.clearMessage();

    if (this.viewerSvc.pageViewed(this.selectedPage) || this.scrolledToBottom || this.isViewingBottom()) {
      // Save the form data if dirty
      if (this.viewerSvc.form.dirty) {
        await this.submit(SubmissionType.Saved, this.task?.id, false);
      }

      this.resetScroll();
      this.errorSvc.clearMessage();
      this.viewerSvc.nextPage();
      this.needsValidation = false;
      this.createMetric(true);
    }
    else {
      this.showNotScrolledError();
    }
  }

  async goPrevPage(): Promise<void> {
    if (this.viewerSvc.form.dirty) {
      await this.submit(SubmissionType.Saved, this.task?.id, false);
    }

    this.resetScroll();
    this.errorSvc.clearMessage();
    this.viewerSvc.prevPage();

    this.createMetric(false);
  }

  async submit(submissionType: SubmissionType, taskId: string = '', postMessage: boolean = true): Promise<void> {
    if (submissionType === SubmissionType.Submitted) {
      if (!this.viewerSvc.pageViewed(this.selectedPage) && !this.scrolledToBottom && !this.isViewingBottom()) {
        this.showNotScrolledError();
        return; // back out of submit if invalid state
      }
      this.btnDisabled = true;
    }

    // Else do submit:
    const submitData = await this.gxProcessing.processFields(this.experience, this.submission?.id, this.selectedPage, this.viewerSvc.form.value, this.submissionSvc);
    const fd = await this.viewerSvc.processCalculations(this.experience, submissionType, this.formData, submitData, this.prefill);
    Object.assign(fd, {  _prefill_c4af0d49_e948_4737_8c12_8d64511faeec: this.prefill ?? { } });
    const metadata = {
      client: platform,
      page: this.selectedPage
    };

    const payload: FormSubmission = {
      id: this.submission?.id ?? this.viewerSvc.formID,
      experienceversionid: this.experience.vid,
      submissiontype: submissionType,
      updatedby: '',
      fileid: null,
      data: fd,
      metadata: metadata,
      taskId: taskId,
      lastupdated: this.submission?.lastupdated || null
    };

    // If there is an existing submission then update, else create a new form
    const upsert = this.submission ?
      this.submissionSvc.update(payload) :
      this.submissionSvc.create(payload);

    this.loadingProgress = 0;
    this.loadingMessage = (submissionType === SubmissionType.Submitted)
      ? this.translateSvc.instant("GX_OVERLAY.PROGRESS_SUBMIT")
      : this.translateSvc.instant("GX_OVERLAY.PROGRESS_SAVE");
    this.loading = true;

    upsert.subscribe(result => {
      switch (result.type) {
        case HttpEventType.Sent: {
          this.loadingProgress = 0;
          break;
        }
        case HttpEventType.UploadProgress :
        case HttpEventType.DownloadProgress: {
          this.loadingProgress = Math.floor(result.loaded / result.total);
          break;
        }
        case HttpEventType.Response: {
          this.loadingProgress = 100;
          this.loadingMessage = (submissionType === SubmissionType.Submitted) ? this.translateSvc.instant("GX_OVERLAY.DONE_SUBMIT") : this.translateSvc.instant("GX_OVERLAY.DONE_SAVE");
          this.submission = result.body;
          this.viewerSvc.formID = result.body.id;
          this.viewerSvc.form.reset(this.viewerSvc.form.value);
          this.viewerSvc.form.markAsPristine();

          setTimeout(() => {
            this.loading = false;
            this.loadingMessage = '';
            this.submitSuccess(result.body, this.task, submissionType, postMessage);
            this.adminSvc.createMetric("CompleteFormViewer",
              {
                "Time": this.time,
                "Experience": this.experience
              });
          }, 1000);
          break;
        }
      }
    }, (err: HttpErrorResponse) => {
      this.loading = false;
      this.loadingProgress = 0;
      this.errorSvc.showErrorMessage(err.message);
      setTimeout(() => {
        this.submitFailure(err);
      }, 2100);
    });
  }

  submitSuccess(submission: FormSubmission, task: TaskDTO, submissionType: SubmissionType, postMessage: boolean = true): void {
    if (this.task?.id) {
      this.router.navigateByUrl('tasks');
    }
    else {
      switch (submissionType) {
        case SubmissionType.Saved: {
          this.toastSvc.success(
            this.translateSvc.instant('TOASTR_MESSAGE.SAVE_SUCCESS'),
            this.translateSvc.instant('TOASTR_MESSAGE.SAVE_TITLE'));
          this.location.go(
            this.router.createUrlTree([], {
              relativeTo: this.activatedRoute,
              queryParams: { formId: submission?.id },
              queryParamsHandling: 'merge' }
            ).toString());
          break;
        }
        case SubmissionType.Submitted: {
          this.toastSvc.success(
            this.translateSvc.instant('TOASTR_MESSAGE.SUBMIT_SUCCESS'),
            this.translateSvc.instant('TOASTR_MESSAGE.SUBMIT_TITLE'));

          this.btnDisabled = false;
          if (this.experience.sendToSuccessPage) {
            this.router.navigateByUrl(`/viewer/${this.experience.id}/${this.experience.vid}/success`);
          } else {
            this.location.go(
              this.router.createUrlTree([], {
                relativeTo: this.activatedRoute,
                queryParams: { formId: submission?.id },
                queryParamsHandling: 'merge'
              }).toString());
          }
          break;
        }
      }
    }
    if (postMessage && window.parent) {
      window.parent.postMessage({
        eventName: (submissionType === SubmissionType.Saved)
          ? WindowMessageEventName.ExperienceSave
          : WindowMessageEventName.ExperienceSubmit,
        formId: submission?.id,
        taskId: task?.id,
      }, document.referrer || "*");
    }
    if (postMessage && window.opener) {
      window.opener.postMessage({
        eventName: (submissionType === SubmissionType.Saved)
          ? WindowMessageEventName.ExperienceSave
          : WindowMessageEventName.ExperienceSubmit,
        formId: submission?.id,
        taskId: task?.id,
      }, window.opener.location.origin || "*");
    }
  }

  submitFailure(err): void {
    console.log('Error: ', err);
    this.btnDisabled = false;
  }

  cancel(): void {
    if (this.task?.id) {
      this.router.navigateByUrl('tasks');
    }
  }

  async valueChanged(field: FieldDTO): Promise<void> {
    if (field?.calculations?.onChange) {
      // TODO: Do we still need this timeout in Spring2021+ to avoid the calculations getting old values?
      setTimeout(async () => {
        const updatedData = await this.viewerSvc.getCalculationData(field.calculations.onChange, this.experience, this.viewerSvc.form.value, this.prefill);
        if (updatedData) {
          Object.entries(updatedData.fields).forEach(([key, val]) => {
            if (this.viewerSvc.form.contains(key)) this.viewerSvc.form.controls[key].setValue(val);
            else this.formData[key] = val;
          });
          this.experience = this.gxProcessing.getUpdatedConfig(this.experience, updatedData.configs);
        }
      });
    }
    // If a Photo Element form value changed, have the Viewer submit a form
    if (field.type === ViewerFieldType.PHOTO) {
      const submitData = await this.gxProcessing.processFields(this.experience, field['formId'], this.selectedPage, this.viewerSvc.form.value, this.submissionSvc);
      const fd = await this.viewerSvc.processCalculations(this.experience, SubmissionType.Saved, this.formData, submitData, this.prefill);

      const metadata = {
        client: platform,
        page: this.selectedPage,
        pageWEB: this.selectedPage,
        pagePDF: 0
      };

      if (this.prefill && Object.keys(this.prefill).length) {
        Object.assign(fd, { _prefill_c4af0d49_e948_4737_8c12_8d64511faeec: this.prefill });
      }
      const payload: FormSubmission = {
        id: field['formId'],
        experienceversionid: this.experience.vid,
        submissiontype: SubmissionType.Saved,
        updatedby: '',
        fileid: null,
        data: fd,
        metadata: metadata,
        taskId: this.task?.id,
        lastupdated: this.submission?.lastupdated || null
      };
      const result = (await this.submissionSvc.update(payload).toPromise());
      const updatedInstance: GuidedExperienceInstanceDTO = Object.assign({}, this.instance$.getValue(), {submission: result.body});
      this.instance$.next(updatedInstance);
      // END Photo Element fork
    }
    this.viewerSvc.removeViewedPage(this.selectedPage);
  }

  createMetric(next: boolean): void {
    const dir = next ? "Next" : "Back"
    this.adminSvc.createMetric("TimeOnPageGXViewer", { "Page": this.selectedPage,
      "Time": this.time, "Direction": dir, "Experience": this.experience })
  }

  onScroll() {
    if (this.isViewingBottom()) {
      this.scrolledToBottom = true;
      this.viewerSvc.addViewedPage(this.selectedPage);
    }
  }

  isViewingBottom() {
    const element = document.getElementById("mainTop");
    return (
      Math.ceil(element.scrollHeight - element.scrollTop) -
        element.clientHeight <=
      50
    );
  }

  resetScroll() {
    document.getElementById("mainTop").style.scrollBehavior = 'auto';
    document.getElementById("mainTop").scrollTo(0,0);
    document.getElementById("mainTop").style.scrollBehavior = 'smooth';

    this.scrolledToBottom = false;
  }

  showNotScrolledError() {
    this.errorSvc.showErrorMessage(
      this.translateSvc.instant('WARNING_RIBBON.SCROLL_TO_BOTTOM_MSG')
    );
  }

  validateRequiredFields() {
    this.needsValidation = true;
  }
}
